import axios from 'axios';
import AppSettings from './AppSettings';
import { Language, Post, Taxonomy, Publication } from './Model';

const taxonomyVersion = AppSettings.getTaxonomyVersion();

export interface SearchDocumentsOptions {
    category?: string;
    limit?: number;
    from?: Date;
    until?: Date;
    types?: String;
    published?: boolean;
    latest?: boolean;
    lang?: string;
}

export default {
    async getTaxonomy(id?: string) {
        const res = await axios.get<Taxonomy>(`/cat/${taxonomyVersion}/${id}`);
        return res.data;
    },
    async searchTaxonomy(parentId?: string) {
        const res = await axios.get<Taxonomy[]>(`/cat/${taxonomyVersion}/list/${parentId}`);
        return res.data;
    },
    async searchLanguage() {
        const res = await axios.get<Language[]>("/language");
        return res.data;
    },
    async searchDocuments(options: SearchDocumentsOptions = {}) {
        options.lang = AppSettings.getCurrentLanguage();
        const res = await axios.get<Publication[]>(`/document`, { params: options });
        return res.data;
    },
    async searchForms(categoryId?: string) {
        const res = await axios.get<[]>(`/form/${categoryId}`);
        return res.data;
    },
    async lookupDocumentTypes() {
        const res = await axios.get<[]>('/lookup/document-type');
        return res.data;
    },
}