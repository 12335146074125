import Vue, { VueConstructor } from 'vue';
import App from './App.vue';
import Index from './components/Index.vue';
import Category from './components/Category.vue';
import FontResizer from './components/FontResizer.vue';
import FormList from './components/FormList.vue';
import CategoryFormList from './components/CategoryFormList.vue';
import LatestDocuments from './components/LatestDocuments.vue';
import NavTaxonomyForms from './components/NavTaxonomyForms.vue';
import RouterViewContent from './components/RouterViewContent.vue';
import DocumentsHome from './components/DocumentsHome.vue';
import NavTaxonomyMobile from './components/NavTaxonomyMobile.vue';
import NavTaxonomyFormsMobile from './components/NavTaxonomyFormsMobile.vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import moment from 'moment';
import AppSettings from './AppSettings';
import CalendarSearch from './components/CalendarSearch.vue';

import NavTaxonomy from './components/NavTaxonomy.vue';

Vue.config.productionTip = false;

// TODO turn off for production
Vue.config.devtools = true;

const currentLanguage = AppSettings.getCurrentLanguage();

moment.locale(currentLanguage);

// TOD
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || 'https://novi-intra.dzs.hr/';
axios.defaults.timeout = 0;
axios.defaults.headers['Accept-Language'] = currentLanguage;

const routes = [
  // { path: '/', component: Index },
  { path: '/v1/documents/:id', component: Category },
  { path: '/v2/documents/:id', component: Category },
  { path: '/v1/forms/:id', component: CategoryFormList },
  { path: '/v2/forms/:id', component: CategoryFormList },
];

const router = new VueRouter({
  routes,
  // removes hashbang from url
  mode: 'history',
});

Vue.use(VueRouter);

interface ComponentInit {
  selector: string;
  component: VueConstructor<Vue>,
}
const availableComponents = new Array<ComponentInit>(
  {
    selector: '#NavTaxonomy',
    component: NavTaxonomy,
  },
  {
    selector: '#NavCategory',
    component: Category,
  },
  {
    selector: '#FontResizer',
    component: FontResizer,
  },
  {
    selector: '#FormList',
    component: FormList,
  },
  {
    selector: '#CategoryFormList',
    component: CategoryFormList,
  },
  {
    selector: '#NavTaxonomyForms',
    component: NavTaxonomyForms,
  },
  {
    selector: '#RouterViewContent',
    component: RouterViewContent,
  },
  {
    selector: '#LatestDocuments',
    component: LatestDocuments,
  },
  {
    selector: '#DocumentsHome',
    component: DocumentsHome,
  },
  {
    selector: '#NavTaxonomyMobile',
    component: NavTaxonomyMobile,
  },
  {
    selector: '#NavTaxonomyFormsMobile',
    component: NavTaxonomyFormsMobile,
  },
  {
    selector: '#CalendarSearch',
    component: CalendarSearch,
  },
);

availableComponents.map(component => {
  if (!document.getElementById(component.selector.replace('#', ''))) {
    // console.log('Vue container not found: ' + component.selector);
    return;
  }
  new Vue({
    router,
    render: (h) => h(component.component),
  }).$mount(component.selector);
});
