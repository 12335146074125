











import { Component, Vue } from "vue-property-decorator";

// TODO css styles, e.g headers etc.

@Component
export default class FontResizer extends Vue {
  private fontSize = 2;

  mount() {
    const fontSize = window.localStorage.getItem("dzs.fontSize");
    if (fontSize) {
      this.fontSize = parseInt(fontSize);
      this.applyResize();
    }
  }

  getStep() {
    return Math.floor(this.fontSize) * 0.2;
  }

  increaseFontSize(version: string) {
    this.fontSize += this.getStep() || 0.2;
    this.applyResize();
  }

  decreaseFontSize(version: string) {
    this.fontSize -= this.getStep();
    this.applyResize();
  }

  applyResize() {
    document.body.style.fontSize = this.fontSize + "em";
  }
}
