







import { Component, Vue } from "vue-property-decorator";
import TaxonomyList from "./TaxonomyList.vue";
import TaxonomyPicker from "./TaxonomyPicker.vue";

@Component({
  components: { TaxonomyList, TaxonomyPicker }
})
export default class NavTaxonomy extends Vue {
  // #breadcrumbs
}
