





































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LatestDocuments from "./LatestDocuments.vue";
import DocumentList from "./DocumentList.vue";
import AppClient from "../AppClient";
import AppSettings from "../AppSettings";
import Datepicker from "vuejs-datepicker";
import { en, hr } from "vuejs-datepicker/dist/locale";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";

@Component({
  components: {
    LatestDocuments,
    Datepicker,
    DocumentList,
    DateRangePicker,
  },
  filters: {
    capitalize(value: string) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
})
export default class DocumentsHome extends Vue {
  documentTypes: [] = [];
  activeTab = "tab1";
  hr = hr;
  en = en;
  showTypesDropdown = false;
  showTypesDropdown1 = false;
  private selectedLanguage: string = "";
  private datePickerLanguage: any;
  private categories: any[] = [];

  private documents: any[] = [];

  private dateFrom: Date = new Date();
  private dateUntil: Date = new Date();

  private selectedDocumentTypes: String[] = [];
  private selectedDocumentTypes1: String[] = [];


  private latestDocumentsLeft: any[] = [];
  private latestDocumentsRight: any[] = [];

  private documentsCategory: string = "";
  private catLevel: number = 0;
  private parent: string = "";

  constructor() {
    super();
    this.dateFrom = new Date();
  }

  highlightedDates() {
    return {
      from: this.dateFrom,
      to: this.dateUntil,
    };
  }

  async mounted() {
    this.documentTypes = await AppClient.lookupDocumentTypes();
    this.selectedLanguage = AppSettings.getCurrentLanguage();
    this.datePickerLanguage = this.selectedLanguage === "en" ? en : hr;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.dateFrom = today;
    this.searchDateRange();
  }

  async loadLatestDocuments() {
    var docs = await AppClient.searchDocuments({
      limit: 10,
      published: true,
      latest: true,
      types: this.selectedDocumentTypes.join(","),
      lang: this.selectedLanguage
    });

    this.latestDocumentsLeft = docs.slice(0, 5);
    this.latestDocumentsRight = docs.slice(5, 10);
  }

  async searchDateRange() {
    this.categories = [];
    if (this.documentsCategory !== "") {
      this.documents = await AppClient.searchDocuments({
        from: this.dateFrom,
        until: this.dateUntil,
        category: this.documentsCategory,
        types: this.selectedDocumentTypes1.join(","),
        lang: this.selectedLanguage
      });
    } else {
      this.documents = await AppClient.searchDocuments({
        from: this.dateFrom,
        until: this.dateUntil,
        types: this.selectedDocumentTypes1.join(","),
        lang: this.selectedLanguage
      });
    }
    if (this.catLevel < 2) {
      if (this.catLevel === 0) {
        for (let document of this.documents) {
          if ( document.area_Id &&
            this.categories.filter((x) => x.id === document.area_Id).length ===
            0
          ) {
            this.categories.push({
              id: document.area_Id,
              name: document.category,
            });
          }
        }
      } else {
        for (let document of this.documents) {
          if ( document.subarea_Id &&
            this.categories.filter(
              (x) => x.id === document.area_Id + "-" + document.subarea_Id
            ).length === 0
          ) {
            this.categories.push({
              id: document.area_Id + "-" + document.subarea_Id,
              name: document.subcategory,
            });
          }
        }
      }
    } else {
      this.categories = [];
    }
  }

  async filterByCategory(category: string, reset: boolean = false) {
    if (!reset) {
      this.catLevel++;
    }
    this.documentsCategory = category;
    this.categories = [];
    this.documents = await AppClient.searchDocuments({
      from: this.dateFrom,
      until: this.dateUntil,
      category: category,
      types: this.selectedDocumentTypes1.join(","),
      lang: this.selectedLanguage
    });
    if (this.catLevel < 2) {
      for (let document of this.documents) {
        if ( document.subarea_Id &&
          this.categories.filter(
            (x) => x.id === document.area_Id + "-" + document.subarea_Id
          ).length === 0
        ) {
          this.categories.push({
            id: document.area_Id + "-" + document.subarea_Id,
            name: document.subcategory,
          });
        }
      }
    }

    if (this.catLevel === 1) {
      this.parent = this.selectedLanguage === "en" ? "Clear" : "Poništi";
    }

    if (this.catLevel === 2) {
      this.parent = this.documents[0].category;
    }

    if (this.catLevel === 3) {
      this.parent = this.documents[0].subcategory;
    }
  }

  async resetCategories() {
    this.catLevel--;
    if(this.catLevel === 0) {
      this.documentsCategory = '';
      this.parent = '';
      await this.searchDateRange();
    } else {
      this.documentsCategory = this.documentsCategory.split('-')[0];
      await this.filterByCategory(this.documentsCategory, true);
    }
  }

  @Watch("dateFrom", { immediate: true })
  private async watchDateFrom(date: Date) {
    this.searchDateRange();
  }

  @Watch("dateUntil", { immediate: true })
  private async watchDateUntil(date: Date) {
    this.searchDateRange();
  }

  @Watch("selectedDocumentTypes", { immediate: true })
  private async onSelectedDocumenTypesChange(types: string[]) {
    this.loadLatestDocuments();
  }

  @Watch("selectedDocumentTypes1", { immediate: true })
  private async onSelectedDocumenTypesChange1(types: string[]) {    
    if(this.catLevel === 0) {
      this.documentsCategory = '';
      this.parent = '';
      await this.searchDateRange();
    } else {
      this.documentsCategory = this.documentsCategory.split('-')[0];
      await this.filterByCategory(this.documentsCategory, true);
    }
  }
}
