



































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import TaxonomyList from "./TaxonomyList.vue";
import DocumentList from "./DocumentList.vue";
import { Taxonomy } from "../Model";
import { Route } from "vue-router";
import AppClient from "../AppClient";
import AppSettings from "../AppSettings";
import JwPagination from "./JwPagination.vue";

@Component({
  components: {
    TaxonomyList,
    DocumentList,
    JwPagination,
  },
})
export default class Index extends Vue {
  private category: {} = {};
  private breadcrumbs: Taxonomy[] = [];
  private taxonomyVersion: string = "v2";
  private showChildren: boolean = false;
  private showDocuments: boolean = false;
  private documents: any[] = [];
  private pageOfItems: any[] = [];
  private everthingIsReady: boolean = false;
  private selectedLanguage: string = "";
  private allDocuments: any[] = [];
  private contDocuments: any[] = [];

  mounted() {
    this.taxonomyVersion = AppSettings.getTaxonomyVersion();
    this.selectedLanguage = this.getLanguage();
  }

  @Watch("$route", { immediate: true })
  private async watchRoute(route: Route) {
    if (route.params.id) {
      this.category = await AppClient.getTaxonomy(route.params.id);
      this.breadcrumbs = await this.getBreadcrumbs(route.params.id);
      this.showChildren = this.breadcrumbs.length < 2;
      this.showDocuments = true;

      this.allDocuments = await AppClient.searchDocuments({
        category: route.params.id,
        published: true,
        latest: true,
      });
      this.documents = this.allDocuments.filter((x) => {
        return x.document_type_id == 1 || x.document_type_id == 2;
      });
      this.contDocuments = this.allDocuments.filter((x) => {
        return x.document_type_id != 1 && x.document_type_id != 2;
      });

      // this.documents = docs.slice(0, this.maxDocuments).map(doc => {
      // doc.showMeta = false;
      // return doc;
      // });
    } else {
      this.category = {};
      this.breadcrumbs = [];
      this.showChildren = false;
      this.showDocuments = false;
    }
  }

  @Watch("documents", { immediate: true })
  private refreshData() {
    if (this.documents.length > 0) {
      this.everthingIsReady = true;
    }
  }

  public onChangePage(pageOfItems: any[]) {
    this.pageOfItems = pageOfItems;
  }

  // TODO dont request each node; store/cache/...
  private async getBreadcrumbs(taxonomyId: string) {
    if (typeof taxonomyId === undefined) return [];
    const breadcrumbs = [];
    const ids = taxonomyId.split("-");
    if (ids.length > 0) {
      for (var i = 0; i < ids.length; i++) {
        const catId = ids.slice(0, i + 1).join("-");
        breadcrumbs.push(await AppClient.getTaxonomy(catId));
      }
    }
    return breadcrumbs;
  }

  getLanguage() {
    const lang = window.localStorage.getItem("dzs.selectedLanguage");
    if (lang === "hr") {
      return lang;
    } else {
      return "en";
    }
  }
}
