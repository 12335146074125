

















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Publication } from "../Model";
import moment from "moment";
import AppSettings from "../AppSettings";

@Component({
  filters: {
    capitalize(value: string) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
})
export default class DocumentList extends Vue {
  @Prop() private documents!: Publication[];
  @Prop({ default: false }) private showPublishDate!: boolean;
  private docs: any[] = [];
  private selectedLanguage: string = "";

  async mounted() {
    console.log(AppSettings.getCurrentLanguage());
    this.selectedLanguage = AppSettings.getCurrentLanguage();
  }

  @Watch("documents", { immediate: true })
  watchDocuments(documents: any[]) {
    this.selectedLanguage = AppSettings.getCurrentLanguage();
    let currentPublishDate = "";
    this.docs = documents.map((doc) => {
      const res = Object.assign({}, doc);

      const publishingDate = doc.actual_publishing_date
        ? doc.actual_publishing_date
        : doc.planned_publishing_date;

      const shortPublishDate = moment(publishingDate).format("L");

      var plannedDate = moment(doc.planned_publishing_date);

      const docItem = Object.assign(res, {
        original_planned_publishing_date: doc.planned_publishing_date,
        original_actual_publishing_date: doc.actual_publishing_date,
        showMeta: false,
        prefixDate: currentPublishDate != shortPublishDate,
        actual_publishing_date1: shortPublishDate,
        planned_publishing_date: plannedDate.format("L"),
        planned_publishing_month: plannedDate.format("MMMM"),
        planned_publishing_day: plannedDate.date(),
        dateToDisplay_year: moment(publishingDate).format("YYYY"),
        dateToDisplay_month: moment(publishingDate).format("MMMM"),
        dateToDisplay_day: moment(publishingDate).date(),
        frequency_abbr: doc.frequency.slice(0, 1).toUpperCase(),
        //show_date:
      });

      docItem.noticeLegend =
        "legend" +
        this.getPublishLegend(moment(publishingDate), docItem.postponed_id, doc);

      currentPublishDate = shortPublishDate;

      return docItem;
    });
  }

  titleCase(str: string) {
    if (AppSettings.getCurrentLanguage() === "en" && str.indexOf(" ") >= 0) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getPublishLegend(date: any, postponed_id: number = 0, document: any) {
    var now = moment();

    if (date.isSame(now, "day")) {
      if (now.hour() < 11) return "3";
      return "2";
    }
    if (date.isBefore(now)) return "2";
    
    if (this.showUpdated(document)) {
      return "4";
    }

    if (postponed_id != null && postponed_id != 0) {
      return "4";
    }

    return "5";
  }

  checkPDFShow(doc: any) {
    if (doc.umbraco_Id == null || doc.umbraco_Id == 0) {
      return false;
    }

    if (
      doc.original_planned_publishing_date == null &&
      doc.original_actual_publishing_date == null
    ) {
      return false;
    }

    // if(doc.correction_type_id != null){
    //   return true;
    // }

    const publishingDate = doc.original_actual_publishing_date
      ? moment(doc.original_actual_publishing_date)
      : moment(doc.original_planned_publishing_date);
    const actualPublishingDateMoment = moment(
      doc.original_actual_publishing_date
    );
    const now = moment();

    if (publishingDate.isSame(now, "day")) {
      if (now.hour() < 11) return false;
      return true;
    }

    if (publishingDate.isBefore(now)) return true;

    if (publishingDate.isAfter(now, "day")) {
      return false;
    }

    return false;
  }

  checkLinkShow(doc: any) {
    if (
      (doc.doc_link_en === null && doc.doc_link_hr === null) ||
      (doc.doc_link_en === "" && doc.doc_link_hr === "")
    ) {
      return false;
    }

    if (
      doc.document_type_id > 2 &&
      doc.document_type_id !== 7
    ) {
      return true;
    }
  }

  showUpdated(doc: any) {
    var dateCheck = doc.actual_publishing_date &&
      doc.planned_publishing_date != doc.actual_publishing_date;
    return dateCheck || (doc.updated && doc.update_status > 0);
  }

  getLanguage() {
    const lang = window.localStorage.getItem("dzs.selectedLanguage");
    if (lang === "hr") {
      return lang;
    } else {
      return "en";
    }
  }

  getAdditionalText(document: any) {
    var dateCheck = document.actual_publishing_date &&
    document.planned_publishing_date != document.actual_publishing_date;

    if(dateCheck) {
      return this.selectedLanguage === "en" ? "Updated" : "Ažurirano"
    }

    if(document.update_status === 1) {
      return this.selectedLanguage === "en" ? "Updated" : "Ažurirano"
    }
    if(document.update_status === 2) {
      return this.selectedLanguage === "en" ? "Unplanned" : "Neplanirano"
    }
    if(document.update_status === 3) {
      return this.selectedLanguage === "en" ? "Cancelled" : "Ukida se"
    }
  }
}
