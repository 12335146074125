






import { Component, Vue } from "vue-property-decorator";
import FormList from "./FormList.vue";

@Component({
  components: { FormList }
})
export default class CategoryFormList extends Vue {}
