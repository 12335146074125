export default {
    getCurrentLanguage() {
        const chunks = window.location.pathname.split('/');
        // TODO default language in .env, from umbraco?
        if (chunks.length > 1 && chunks[1] === 'en') {
            return 'en';
        }
        return 'hr';
        return window.localStorage.getItem("dzs.language") || "en";
    },
    getTaxonomyVersion() {
        // TODO infer from url?
        return window.localStorage.getItem("dzs.taxonomyVersion") || "v2";
    }
}