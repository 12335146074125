














































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AppClient from "../AppClient";
import AppSettings from "../AppSettings";

@Component
export default class FormList extends Vue {
  @Prop() private categoryId!: string;
  @Prop({ default: 10 }) private maxDocuments!: number;
  private forms = [];

  @Watch("categoryId", { immediate: true })
  async watchCategoryId(value: string) {
    if (value) {
      this.forms = await AppClient.searchForms(value || "");
    }
  }
}
