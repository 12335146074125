













import { Component, Vue } from "vue-property-decorator";

@Component
export default class TaxonomyPicker extends Vue {
  private setVersion(version: string) {
    window.localStorage.setItem("dzs.taxonomyVersion", version);
    window.location.assign("/");
  }
}
