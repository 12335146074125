




import { Component, Vue } from "vue-property-decorator";
import TaxonomyList from "./TaxonomyList.vue";

@Component({
  components: { TaxonomyList }
})
export default class NavTaxonomyFormsMobile extends Vue {}
