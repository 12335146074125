




import { Component, Vue } from "vue-property-decorator";
import DocumentList from "./DocumentList.vue";
import AppClient from "../AppClient";

@Component({
  components: {
    DocumentList
  }
})
export default class LatestDocuments extends Vue {
  private documents: any[] = [];

  async mounted() {
    this.documents = await AppClient.searchDocuments({ limit: 10 });
  }
}
