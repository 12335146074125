















import { Taxonomy } from "../Model";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AppClient from "../AppClient";
import AppSettings from "../AppSettings";

// hard-coded icons for MVP
// TODO replace in db...
const iconMap: { [id: number]: string } = {
  1: "Poljoprivreda", // Poljoprivreda
  2: "Asset-22", // Industrija
  3: "Asset-17", // Građevinarstvo
  4: "Asset-10", // Trgovina i ostale usluge
  5: "Asset-24", // Transport
  6: "Asset-18", // Okoliš i energija
  7: "Asset-19", // Stanovništvo
  8: "Kultura", // Kultura
  9: "Asset-14", // Tržište rada
  10: "Asset-9", // Pravosuđe i socijalna zaštita
  11: "Asset-21", // Registar poslovnih subjekata
  12: "Asset-26", // BDP i nacionalni računi
  13: "Cijene", // Cijene
  14: "Asset-11", // Životni uvjeti, dohodak i potrošnja kućanstva
  15: "Asset-16", // Strukturne i poslovne statistike
  41: "Asset-15", // Robna razmjena s inozemstvom
  43: "Turizam", // Turizam
  81: "Asset-13", // Obrazovanje
  82: "Asset-25" // Znanost, tehnologija, informacijsko društvo
};

@Component
export default class TaxonomyList extends Vue {
  @Prop() private parentId!: string;
  @Prop() private showIcons!: boolean;
  @Prop({ default: "documents" }) private targetPath!: string;
  @Prop({ default: "" }) private listId!: string;
  @Prop({ default: "" }) private listClass!: string;
  @Prop({ default: "" }) private listItemClass!: string;

  private items: Taxonomy[] = [];
  private taxonomyVersion = "v2";
  private selectedLanguage: string = "hr";

  async mounted() {
    this.taxonomyVersion = AppSettings.getTaxonomyVersion();
    this.selectedLanguage = AppSettings.getCurrentLanguage();
  }

  getIconAsset(id: string) {
    const iconId = iconMap[parseInt(id)];
    return "icon-" + (iconId ? iconId : "26");
  }

  @Watch("parentId", { immediate: true })
  async watchParentId(value: string) {
    const parentId = this.parentId || "";
    this.items = await AppClient.searchTaxonomy(parentId);
  }

  @Watch("showIcons", { immediate: true })
  async watchShowIcons(value: string) {}
}
