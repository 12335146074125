






import { Component, Vue } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import {en, hr} from 'vuejs-datepicker/dist/locale';
import AppSettings from "../AppSettings";

@Component({
  components: { Datepicker }
})
export default class CalendarSearch extends Vue {
  hr = hr;
  en = en;
  private selectedLanguage: string = "";
  private datePickerLanguage: any;

  async mounted() {
    this.selectedLanguage = AppSettings.getCurrentLanguage();
    this.datePickerLanguage = this.selectedLanguage === 'en' ? en : hr;
  }
}
